import { Injectable } from '@angular/core';
import { environment } from '@config/environments/environment';
import { GLOBAL_CONFIG_PAGE_SIZE } from '@config/ui.const';

declare var $: any;
// TODO: Implement in future
export interface IConfigDisplayDataGird {
  language: any;
};

@Injectable()
export class DataTableLoaderService {
  private readonly DEFAULT_PAGINATE = GLOBAL_CONFIG_PAGE_SIZE.pageSizeOptions;
  table: any;
  constructor() { }

  showDataGird(parentElementId: string, options: object, display: IConfigDisplayDataGird) {
    $.extend(true, $.fn.dataTable.defaults, {
      lengthMenu: [[...this.DEFAULT_PAGINATE, -1], [...this.DEFAULT_PAGINATE, "All"]],
      language: display.language
    });
    this.table = $(`#${parentElementId}`)?.dataTable(options);
    return this.table;
  }

}
